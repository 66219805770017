import React, {useState} from 'react'

function FaqItem({
    activeFaq,
    answer,
    index,
    question,
    updateActiveFaq,
}) {

    return (
        <li className={`faq ${activeFaq === index ? "open" : ""}`} onClick={() => updateActiveFaq(index)}>
            <h3 className="faq-question font-lato">{question}</h3>
            <p className="faq-answer font-lato">{answer}</p>
        </li>
    )

}

export default FaqItem