import React, {useState} from 'react'
import { graphql } from "gatsby"
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import FaqItem from '../../components/faq/faq-item'
import './faq.css';

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {title: {eq: "FAQs"}}}) {
      edges {
          node {
              frontmatter {
                  faq {
                      question
                      answer
                  }
              }
          }
      }
    }
  }
`

const FAQ = ({data}) => {
    const faqs = data.allMarkdownRemark.edges[0].node.frontmatter.faq;
    let [activeFaq, setActiveFaq] = useState(null)

    const updateActiveFaq = (index) => {
        if (index === activeFaq) {
            setActiveFaq(null);
        } else {
            setActiveFaq(index);
        }
    };

    return(
        <Layout
            pageClass="faq"
            offsetHeader={true}
        >
            <Seo title="FAQ" />
            <h1 className="faq-page-title font-lato mt-50">FAQ</h1>
            <section className="faq-section">
                <div className="faq-container">
                    <ul className="faqs">
                        {
                            faqs.map((faq, index) => {
                                return (
                                    <FaqItem 
                                        activeFaq={activeFaq}
                                        answer={faq.answer}
                                        index={index}
                                        key={`faq-${index}`}  
                                        question={faq.question} 
                                        updateActiveFaq={updateActiveFaq}
                                    />                            
                                )
                            })
                        }
                    </ul>
                </div>
            </section>
        </Layout>
    )
}

export default FAQ